<template>
  <div
    class="mt-5"
    v-if="$store.state.showPrice && $store.state.price.total != 0"
  >
    <div class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3">
      <MainTitle msg="MainTitle.Pricing" />
      <SubTitle msg="" />
      <div
        v-for="(deurgeheel, index) in $store.state.price.deurgehelen"
        v-bind:key="index"
        class="mx-3"
      >
        <div
          v-for="(item, key) in deurgeheel"
          v-bind:key="index + '_' + key"
          class="row"
        >
          <div class="col-6 text-start small" v-if="key != 'total'">
            {{ $t("Pricing." + key.replace(".", "_")) }}
          </div>
          <div class="col-6 text-end small" v-if="key != 'total'">
            €
            {{
              parseFloat((item / 1).toFixed(2)).toLocaleString("nl-BE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            }}
          </div>
        </div>
        <div class="row border-top">
          <div class="col-6 text-start small">
            <strong>{{ $t("Pricing.subtotal") }}</strong>
          </div>
          <div class="col-6 text-end small">
            <strong>
              €
              {{
                parseFloat((deurgeheel.total / 1).toFixed(2)).toLocaleString(
                  "nl-BE",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )
              }}
            </strong>
          </div>
        </div>
      </div>
      <div class="mx-3">
        <div class="row">
          <div class="col-6 text-start">
            <strong>{{ $t("Pricing.total") }}</strong>
          </div>
          <div class="col-6 text-end">
            <strong>
              €
              {{
                parseFloat(
                  ($store.state.price.total / 1).toFixed(2)
                ).toLocaleString("nl-BE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
            </strong>
          </div>
          <div class="col-12 text-end small">
            {{ $t("Pricing.btw") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PriceScreen",
  data() {
    return {};
  },
};
</script>
