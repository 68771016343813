<template>
  <div v-if="$store.state.kadermaat.Breedte > 1010">
    <SubTitle msg="SubTitle.AsTools" :showQuestion="true" helpProp="astools" />

    <BaseInfo
      :msg="$t('AsTools.TextTool')"
      :error="this.astool1keuze != 1 || this.astool2keuze != 1"
      v-if="$store.state.kadermaat.Breedte <= 1200"
    />
    <BigButton
      :src="require('@/assets/AsTools/ASTOOL1.png')"
      msg="AsTools.AsTool1"
      :selected="astool1keuze == 1"
      v-on:click.prevent="selectASTool1(1)"
      v-if="$store.state.kadermaat.Breedte <= 1200"
    />
    <BigButton
      :src="require('@/assets/AsTools/ASTOOL2.png')"
      msg="AsTools.AsTool2"
      :selected="astool2keuze == 1"
      v-on:click.prevent="selectASTool2(1)"
      v-if="$store.state.kadermaat.Breedte <= 1200"
    />
    <BaseInfo
      :msg="$t('AsTools.TextToolXL')"
      :error="this.astool1keuze != 2 || this.astool2keuze != 2"
      v-if="$store.state.kadermaat.Breedte > 1200"
    />
    <BigButton
      :src="require('@/assets/AsTools/ASTOOL1XL.png')"
      msg="AsTools.AsTool1XL"
      :selected="astool1keuze == 2"
      v-on:click.prevent="selectASTool1(2)"
      v-if="$store.state.kadermaat.Breedte > 1200"
    />
    <BigButton
      :src="require('@/assets/AsTools/ASTOOL1XL.png')"
      msg="AsTools.AsTool2XL"
      :selected="astool2keuze == 2"
      v-on:click.prevent="selectASTool2(2)"
      v-if="$store.state.kadermaat.Breedte > 1200"
    />
    <HelpAsTools v-if="$store.state.helpProperty.astools" />
  </div>
</template>

<script>
import HelpAsTools from "@/components/help/HelpAsTools.vue";
export default {
  name: "AlTools",
  components: {
    HelpAsTools,
  },
  data() {
    return {};
  },
  watch: {
    "$store.state.kadermaat.Breedte": function () {
      if (this.$store.state.kadermaat.Breedte > 1010) {
        if (this.astool1keuze != 0) {
          this.astool1keuze =
            this.$store.state.kadermaat.Breedte <= 1200 ? 1 : 2;
        }
        if (this.astool2keuze != 0) {
          this.astool2keuze =
            this.$store.state.kadermaat.Breedte <= 1200 ? 1 : 2;
        }
      } else {
        this.astool1keuze = 0;
        this.astool2keuze = 0;
      }
    },
  },
  computed: {
    astool1keuze: {
      get() {
        return this.$store.state.astool1keuze;
      },
      set(value) {
        this.$store.commit("setAstool1keuze", value);
      },
    },
    astool2keuze: {
      get() {
        return this.$store.state.astool2keuze;
      },
      set(value) {
        this.$store.commit("setAstool2keuze", value);
      },
    },
  },
  methods: {
    selectASTool1(_selected) {
      this.astool1keuze = this.astool1keuze == 0 ? _selected : 0;
    },
    selectASTool2(_selected) {
      this.astool2keuze = this.astool2keuze == 0 ? _selected : 0;
    },
  },
};
</script>
