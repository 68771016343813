<template>
  <div class="hideOnScreen">
    <div class="mt-5">
      <div class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3">
        <MainTitle msg="OfferteFormulier.MainTitleVerdouw" />
        <SubTitle msg="" />
        <div class="m-3">
          <div class="row">
            <div class="col-6 text-start">
              <p class="small m-0">{{ $store.state.order.klantref }}</p>
              <p class="small m-0">
                {{ $store.state.qouteForm.firstname }}
                {{ $store.state.qouteForm.lastname }}
              </p>
              <p class="small m-0">{{ $store.state.qouteForm.street }}</p>
              <p class="small m-0">
                {{ $store.state.qouteForm.zip }}
                {{ $store.state.qouteForm.municipality }}
              </p>
              <p class="small m-0">{{ $store.state.qouteForm.country }}</p>
            </div>
            <div class="col-6 text-end">
              <p class="small m-0">
                <strong>{{ $t("OfferteFormulier.tel") }}</strong>
              </p>
              <p class="small m-0">{{ $store.state.qouteForm.phone }}</p>

              <p class="small m-0">
                <strong>{{ $t("OfferteFormulier.email") }}</strong>
              </p>
              <p class="small m-0">{{ $store.state.qouteForm.mail }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PriceScreen />
    <div
      v-for="(item, index) in $store.state.drawings"
      v-html="item"
      :key="index"
    ></div>
  </div>
</template>
<script>
import PriceScreen from "@/components/PriceScreen.vue";
export default {
  name: "PrintScreen",
  components: {
    PriceScreen,
  },
  watch: {
    "$store.state.redrawFix": function () {
      this.getDrawings();
    },
  },
  methods: {
    async getDrawings() {
      await this.$store.dispatch("getDrawings");
    },
  },
};
</script>
