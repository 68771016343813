<template>
  <div>
    <SubTitle msg="" />
    <div class="row align-items-center">
      <div class="col-8">
        <BaseInfo :msg="$t('HelpDraaipunt.Info01')" />
        <div class="mb-4"></div>
        <BaseInfo :msg="$t('HelpDraaipunt.Info02')" />
      </div>
      <div class="col-3 text-center">
        <img src="@/assets/Help/helpDraaipunt.png" style="height: 150px" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelpDraaipunt",
};
</script>
