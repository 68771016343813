<template>
  <div class="row g-0 align-items-center ms-3 mb-3">
    <div class="col-12" style="font-size: 0.8em">
      <img src="@/assets/GroenFiguur.png" class="img-fluid" v-if="!error" />
      <img src="@/assets/RoodFiguur.png" class="img-fluid" v-if="error" />
      {{ msg }}
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseInfo",
  props: {
    msg: String,
    error: Boolean,
  },
};
</script>
