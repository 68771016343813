<template>
  <PriceScreen />
</template>
<script>
import PriceScreen from "@/components/PriceScreen.vue";

export default {
  name: "PriceCalcScreen",
  components: {
    PriceScreen,
  },
  data() {
    return {};
  },
  watch: {
    "$store.state.priceFix": function () {
      this.getPrice();
    },
  },
  computed: {
    user_name: {
      get() {
        return this.$store.state.sec.user_name;
      },
      set(value) {
        this.$store.commit("setUserName", value);
      },
    },
    user_pwd: {
      get() {
        return this.$store.state.sec.user_pwd;
      },
      set(value) {
        this.$store.commit("setUserPwd", value);
      },
    },
  },
  methods: {
    async getPrice() {
      if (
        (this.user_name != "" && this.user_pwd != "") ||
        this.$store.state.token != "INVALID"
      ) {
        const priceObj = await this.$store.dispatch("getPrice");
        if (priceObj != 0) {
          this.$store.state.price = priceObj;
        } else {
          this.$store.state.price = 0;
        }
      }
    },
  },
};
</script>
