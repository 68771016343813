<template>
  <div>
    <SubTitle msg="" />
    <div class="row align-items-center">
      <div class="col-6">
        <BaseInfo :msg="$t('HelpAsTools.Info01')" />
        <BaseInfo :msg="$t('HelpAsTools.Info02')" />
        <BaseInfo :msg="$t('HelpAsTools.Info03')" />
        <BaseInfo :msg="$t('HelpAsTools.Info04')" />
      </div>
      <div class="col-6">
        <img src="@/assets/Help/ASTOOLSHelp.png" class="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelpAsTools",
};
</script>
