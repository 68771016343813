<template>
  <div>
    <SubTitle msg="" />
    <div class="row align-items-center">
      <div class="col-12">
        <BaseInfo :msg="$t('HelpOnderverdeling.Info03')" />
      </div>
      <div class="col-4 text-center">
        <img src="@/assets/Help/A.png" style="height: 150px" />
      </div>
      <div class="col-4 text-center">
        <img src="@/assets/Help/B.png" style="height: 150px" />
      </div>
      <div class="col-4 text-center">
        <img
          src="@/assets/Help/horizontaleOnderverdelingenMiddenDeugreep.png"
          style="height: 150px"
        />
      </div>
    </div>

    <div class="row align-items-center" v-if="$store.state.token != 'INVALID'">
      <div class="col-12">
        <BaseInfo :msg="$t('HelpOnderverdeling.Info04')" />
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-6">
        <BaseInfo :msg="$t('HelpOnderverdeling.Info01')" />
      </div>
      <div class="col-6 text-center">
        <img src="@/assets/Help/helpVerticaal.png" style="height: 150px" />
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-6">
        <BaseInfo :msg="$t('HelpOnderverdeling.Info02')" />
      </div>
      <div class="col-6 text-center">
        <img src="@/assets/Help/helpHorizontaal.png" style="height: 150px" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelpOnderverdelingen",
};
</script>
