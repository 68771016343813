<template>
  <button
    class="border border-light shadow-sm px-2 rounded-pill mx-1"
    :class="[selected ? 'bg-light' : 'bg-body']"
  >
    {{ $t(msg) }}
  </button>
</template>
<script>
export default {
  name: "SmallButton",
  props: {
    msg: String,
    selected: Boolean,
  },
};
</script>
