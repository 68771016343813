<template>
  <!-- EXTRA //-->
  <div class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5">
    <MainTitle msg="MainTitle.Color" />
    <div class="p-0 pt-2 mb-3 mt-5">
      <BigButton
        :color="$store.state.lakkleuren[0]"
        :msg="'ColorList.Default'"
        :selected="this.isDefault"
        v-on:click.prevent="setLakDefault()"
        :width="20"
        v-if="isDefaultSelectable"
      />
      <BigButton
        color="all"
        :src="require('@/assets/ColorCircle.png')"
        :msg="'ColorList.Other'"
        imgSize="50px;"
        :selected="!this.isDefault"
        v-on:click.prevent="setLakOtherColor()"
        :width="20"
      />
    </div>
    <div v-if="!this.isDefault">
      <BaseInfo :msg="$t('HelpRalkleur.Error01')" :error="true" />
      <SubTitle
        msg="SubTitle.Ralkleur"
        :showQuestion="true"
        helpProp="ralkleur"
      />
      <template v-for="item in [0, 1, 2, 3, 4]" :key="item">
        <BigButton
          :color="$store.state.lakkleuren[item]"
          :msg="'ColorList.' + item"
          :selected="this.lakkleur == item"
          v-on:click.prevent="setLakkleur(item)"
          :width="20"
        />
      </template>
      <HelpRalkleur v-if="$store.state.helpProperty.ralkleur" />

      <div v-if="this.lakkleur <= 4">
        <SubTitle
          msg="SubTitle.Lakafwerking"
          :showQuestion="true"
          helpProp="lakafwerking"
        />
        <div class="m-2">
          <SmallButton
            :msg="'SelecteerKleur.structuur'"
            :selected="this.lakafwerking == 0"
            v-on:click.prevent="setLakAfwerking(0)"
          />
          <SmallButton
            :msg="'SelecteerKleur.mat'"
            :selected="this.lakafwerking == 1"
            v-on:click.prevent="setLakAfwerking(1)"
          />
          <SmallButton
            :msg="'SelecteerKleur.glanzend'"
            :selected="this.lakafwerking == 2"
            v-on:click.prevent="setLakAfwerking(2)"
          />
        </div>
        <HelpLakafwerking v-if="$store.state.helpProperty.lakafwerking" />
      </div>
      <SubTitle msg="SubTitle.Anodic" :showQuestion="true" helpProp="anodic" />

      <template v-for="item in [5, 6, 7, 8, 9, 10, 11]" :key="item">
        <BigButton
          :color="$store.state.lakkleuren[item]"
          :msg="'ColorList.' + item"
          :selected="this.lakkleur == item"
          v-on:click.prevent="setLakkleur(item)"
          :width="20"
        />
      </template>
      <HelpAnodic v-if="$store.state.helpProperty.anodic" />
    </div>
  </div>
  <!-- END EXTRA //-->
</template>
<script>
import HelpRalkleur from "@/components/help/HelpRalkleur.vue";
import HelpLakafwerking from "@/components/help/HelpLakafwerking.vue";
import HelpAnodic from "@/components/help/HelpAnodic.vue";
export default {
  name: "ColorSelect",
  components: {
    HelpRalkleur,
    HelpLakafwerking,
    HelpAnodic,
  },
  data() {
    return {
      isDefault: true,
      isDefaultSelectable: true,
    };
  },
  computed: {
    lakkleur: {
      get() {
        return this.$store.state.lakkleur;
      },
      set(value) {
        this.$store.commit("setLakkleur", value);
      },
    },
    lakafwerking: {
      get() {
        return this.$store.state.lakafwerking;
      },
      set(value) {
        this.$store.commit("setLakAfwerking", value);
      },
    },
  },
  watch: {
    "$store.state.hendelKeuze": function () {
      if (this.$store.state.hendelKeuze == 13) {
        if (this.isDefault) {
          this.setLakOtherColor();
        }
        this.isDefaultSelectable = false;
      } else {
        this.isDefaultSelectable = true;
        if (this.lakafwerking == 0 && this.lakkleur == 0) {
          this.setLakDefault();
        }
      }
    },
  },
  methods: {
    setLakkleur(index) {
      this.lakkleur = index;
    },
    setLakAfwerking(index) {
      this.lakafwerking = index;
    },
    setLakDefault() {
      this.lakafwerking = 0;
      this.lakkleur = 0;
      this.isDefault = true;
    },
    setLakOtherColor() {
      this.isDefault = false;
    },
  },
};
</script>
