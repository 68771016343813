<template>
  <div
    class="bg-light"
    style="
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 99999;
    "
    v-if="showMe"
  >
    <div class="container mt-5 p-5">
      <div class="row">
        <div class="col-4 offset-4">
          <h1>Aanmelden</h1>
          <form id="loginForm">
            <div class="p-4">
              <div class="input-group mb-3">
                <span class="input-group-text bg-primary"
                  ><i class="bi bi-person-plus-fill text-white"></i
                ></span>
                <input
                  type="text"
                  id="user_name"
                  class="form-control"
                  placeholder="username"
                  v-model="user_name"
                />
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text bg-primary"
                  ><i class="bi bi-key-fill text-white"></i
                ></span>
                <input
                  type="password"
                  id="user_pwd"
                  class="form-control"
                  placeholder="password"
                  v-model="user_pwd"
                />
              </div>
              <button
                class="btn btn-primary text-center mt-2"
                type="submit"
                v-on:click.prevent="getLoginToken()"
              >
                Aanmelden
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoginScreen",
  data() {
    return {
      showMe: false,
    };
  },
  computed: {
    Token: {
      get() {
        return this.$store.state.token;
      },
      set(value) {
        this.$store.commit("setToken", value);
      },
    },
    user_name: {
      get() {
        return this.$store.state.sec.user_name;
      },
      set(value) {
        this.$store.commit("setUserName", value);
      },
    },
    user_pwd: {
      get() {
        return this.$store.state.sec.user_pwd;
      },
      set(value) {
        this.$store.commit("setUserPwd", value);
      },
    },
  },
  mounted: function () {
    if (this.$store.getters.getCustomizationNumber == 4) {
      this.showMe = true;
    } else {
      this.showMe = false;
    }
  },
  methods: {
    async getLoginToken() {
      const token = await this.$store.dispatch("getLoginToken");
      if (token != 0) {
        this.showMe = false;
        this.$store.commit("toggleShowPrice");
        this.$store.state.priceFix++;
      }
    },
  },
};
</script>
