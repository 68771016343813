<template>
  <div>
    <SubTitle msg="" />
    <div class="row align-items-center">
      <div class="col-6">
        <BaseInfo :msg="$t('HelpDeurgrepen.Info01')" />
        <div class="mb-6"></div>
        <BaseInfo :msg="$t('HelpDeurgrepen.Info02')" />
      </div>
      <div class="col-6 text-center">
        <img src="@/assets/Help/helpDeurgreep.png" style="height: 150px" />
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-6">
        <BaseInfo :msg="$t('HelpDeurgrepen.Info03')" />
      </div>
      <div class="col-6 text-center">
        <img
          src="@/assets/Help/HelftLengteHoekgreepPlus68.png"
          style="height: 300px"
        />
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-6">
        <BaseInfo :msg="$t('HelpDeurgrepen.Info04')" />
      </div>
      <div class="col-6 text-center">
        <img
          src="@/assets/Help/HelftLengteHoekgreep.png"
          style="height: 300px"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelpDeurgrepen",
};
</script>
