<template>
  <div class="position-relative">
    <div class="position-absolute top-0 start-50 translate-middle">
      <h1
        class="mx-5 p-3 text-center font-egdl fs-6 text-white fw-light bg-primary rounded-pill"
        style="width: 300px"
      >
        {{ $t(msg) }}
      </h1>
    </div>
  </div>
</template>
<script>
export default {
  name: "MainTitle",
  props: {
    msg: String,
  },
};
</script>
