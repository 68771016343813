<template>
  <button
    class="border border-light shadow-sm pt-1 rounded-circle mx-1 bg-white"
  >
    ?
  </button>
</template>
<script>
export default {
  name: "ButtonQuestion",
};
</script>
